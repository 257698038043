/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { CheckCircleOutline, ErrorOutline } from '@mui/icons-material'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography
} from '@mui/material'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { RingLoader } from 'react-spinners'
import PageTitle from '../../shared/components/page-title'
import AuthService from '../../shared/services/auth/auth.service'
import { MessageServices } from '../../shared/services/message/message.service'
import { ActivitiesContainer } from '../activities/style'
import { MyAccountStyle } from './style'

const MyAccount: React.FC = () => {
  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmNewPassword, setConfirmNewPassword] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [confirmPasswordError, setConfirmPasswordError] = useState('')
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [deleteConfirmation, setDeleteConfirmation] = useState('')
  const [loading, setLoading] = useState(false)

  const authService = new AuthService()
  const messageService = new MessageServices()
  const navigate = useNavigate()

  const validateNewPassword = () => {
    if (!newPassword) {
      setPasswordError('Campo senha é obrigatório')
      return false
    }
    const validations = [
      newPassword.length >= 8,
      /[A-Z]/.test(newPassword),
      /[a-z]/.test(newPassword),
      /\d/.test(newPassword)
    ]
    const isValid = validations.every(validation => validation)
    if (!isValid) {
      setPasswordError(
        'Senha deve conter pelo menos 8 caracteres, uma letra maiúscula, uma letra minúscula e um número'
      )
      return false
    }
    setPasswordError('')
    return true
  }

  const validateConfirmNewPassword = () => {
    if (newPassword !== confirmNewPassword) {
      setConfirmPasswordError('As senhas não coincidem')
      return false
    }
    setConfirmPasswordError('')
    return true
  }

  const handleChangePassword = async (e: any) => {
    e.preventDefault()
    setLoading(true)
    const isNewPasswordValid = validateNewPassword()
    const isConfirmNewPasswordValid = validateConfirmNewPassword()
    if (isNewPasswordValid && isConfirmNewPasswordValid) {
      try {
        await authService.updatePassword(currentPassword, newPassword)
        navigate('/dashboard')
        messageService.success('Sucesso', 'Senha alterada com sucesso')
      } catch (error: any) {
        messageService.error('Erro', error.message)
      }
    }
    setLoading(false)
  }

  const handleDeleteAccount = async () => {
    if (deleteConfirmation !== 'Quero deletar minha conta') {
      messageService.error(
        'Erro',
        'Você deve digitar a frase correta para deletar a conta'
      )
      return
    }

    setLoading(true)
    await authService.deleteAccount()
    messageService.success('Sucesso', 'Conta deletada com sucesso')
    navigate('/login')
    setLoading(false)
  }

  return (
    <>
      <ActivitiesContainer>
        <PageTitle prop={{}} />
        <div
          className="d-flex animate__animated animate__fadeIn p-5 d-flex justify-content-center align-items-center"
          style={{ width: '85dvw', height: '85dvh', overflowX: 'hidden' }}
        >
          <MyAccountStyle.Form
            onSubmit={handleChangePassword}
            autoComplete="off"
            className="form h-100 p-5 w-60"
          >
            <Typography variant="h4" className="mb-2">
              Minha Conta
            </Typography>
            <TextField
              size="small"
              className="w-100 mt-4"
              id="current-password-field"
              label="Senha Atual"
              variant="outlined"
              type="password"
              value={currentPassword}
              onChange={e => setCurrentPassword(e.target.value)}
            />
            <TextField
              size="small"
              className={`w-100 mt-4 ${passwordError ? 'error' : ''}`}
              id="new-password-field"
              label="Nova Senha"
              variant="outlined"
              type="password"
              error={!!passwordError}
              helperText={
                <small className="text-danger">{passwordError}</small>
              }
              value={newPassword}
              onChange={e => setNewPassword(e.target.value)}
            />
            <div className="d-flex flex-column mt-3">
              {[/[A-Z]/, /[a-z]/, /\d/].map((regex, index) => (
                <div key={index} className="d-flex align-items-center">
                  {regex.test(newPassword) ? (
                    <CheckCircleOutline sx={{ color: 'green' }} />
                  ) : (
                    <ErrorOutline sx={{ color: 'red' }} />
                  )}
                  <Typography variant="body2" className="ms-2">
                    {index === 0
                      ? 'Pelo menos uma letra maiúscula'
                      : index === 1
                      ? 'Pelo menos uma letra minúscula'
                      : 'Pelo menos um número'}
                  </Typography>
                </div>
              ))}
              <div className="d-flex align-items-center">
                {newPassword.length >= 8 ? (
                  <CheckCircleOutline sx={{ color: 'green' }} />
                ) : (
                  <ErrorOutline sx={{ color: 'red' }} />
                )}
                <Typography variant="body2" className="ms-2">
                  Pelo menos 8 caracteres
                </Typography>
              </div>
            </div>
            <TextField
              size="small"
              className={`w-100 mt-4 ${confirmPasswordError ? 'error' : ''}`}
              id="confirm-new-password-field"
              label="Confirmar Nova Senha"
              variant="outlined"
              type="password"
              error={!!confirmPasswordError}
              helperText={
                <small className="text-danger">{confirmPasswordError}</small>
              }
              value={confirmNewPassword}
              onChange={e => setConfirmNewPassword(e.target.value)}
            />
            <Button
              type="submit"
              className="submit-button w-100 mt-3 px-3 py-2"
              variant="contained"
              color="primary"
            >
              Trocar Senha
            </Button>
            <Button
              className="delete-button w-100 mt-3 px-3 py-2"
              variant="outlined"
              color="error"
              onClick={() => setDeleteDialogOpen(true)}
            >
              Deletar Conta
            </Button>
            <Dialog
              open={deleteDialogOpen}
              onClose={() => setDeleteDialogOpen(false)}
            >
              <DialogTitle>Deletar Conta</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Tem certeza de que deseja deletar sua conta? Esta ação não
                  pode ser desfeita. Para confirmar, digite "Quero deletar minha
                  conta" no campo abaixo.
                </DialogContentText>
                <TextField
                  autoFocus
                  margin="dense"
                  id="delete-confirmation"
                  label="Confirmação"
                  type="text"
                  fullWidth
                  variant="standard"
                  value={deleteConfirmation}
                  onChange={e => setDeleteConfirmation(e.target.value)}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => setDeleteDialogOpen(false)}
                  color="primary"
                >
                  Cancelar
                </Button>
                <Button onClick={handleDeleteAccount} color="error">
                  Deletar
                </Button>
              </DialogActions>
            </Dialog>
          </MyAccountStyle.Form>
        </div>
      </ActivitiesContainer>
      {loading && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <RingLoader color="#00df70" loading={true} />
        </div>
      )}
    </>
  )
}

export default MyAccount
