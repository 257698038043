import React from 'react'

type FormattedScoreProps = {
  value: number | null | undefined | string
}

const FormattedScore: React.FC<FormattedScoreProps> = ({ value }) => {
  if (typeof value === 'string') return <>{value}</>
  return <>{value !== null && value !== undefined ? value.toFixed(0) : '-'}</>
}

export default FormattedScore
